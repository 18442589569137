import './App.css';
import { Welcome } from './Components/main'
import {Mobile} from './Components/mobile'
import * as React from 'react';



function App() {
  const isMobile = window.screen.width < 600


  return (
  <>
    {!isMobile && <Welcome />}
    {isMobile && <Mobile />}
  </>
  );
}

export default App;

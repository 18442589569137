
import './main.css'
import Logo from './../assets/GIDLOGO.png'
import insta from './../assets/Instagram.png'
import animation from './../assets/animation.gif'

export function Welcome(props) {
    function handleLinkClick() {
        window.location = "https://www.instagram.com/gidgrafik/"
    }

    return (
        <div className='box'>
            <div className='box-item'>
                <div className='box-item3'>
                    <img width='60%' src={Logo} alt='gid logo'></img>
                    <p className='subtitle'>
                        DRUCKWERKE<br/> PACKAGE DESIGN<br/>SOCIAL MEDIA<br/> ONLINE MARKETING<br/> SCREENDESIGN<br/> GIF ANIMATION ILLUSTRATION
                    </p>
                    <p className='text'>
                        „Mit großer Leidenschaft und
                        viel Liebe zu schönem Design,
                        vor allem für die Wertschätzung
                        gegenüber unserer Kunden,
                        wird jedes Projekt von uns mit
                        größtem Engagement und dem
                        immerwährenden Streben nach
                        Perfektion umgesetzt.“
                    </p>
                    <a href='https://sweetpopcorn.studio' className='text'>Partner: sweetpopcorn.studio</a>
                </div>
                <div className='box-item4'>
                    <img className='animation' src={animation} alt='gid animation'></img>
                </div>


                <div className='box-item3'>
                    <div className='fullSize'>
                        <hr
                            style={{
                                background: 'black',
                                color: 'black',
                                borderColor: 'black',
                                height: '1.5pt',
                            }}
                        />
                        <div className='contact'>
                            <a href='tel:+436648787439'>T +43.664.8787439</a>
                        </div>
                        <hr
                            style={{
                                background: 'black',
                                color: 'black',
                                borderColor: 'black',
                                height: '1.5pt',
                            }}
                        />
                        <div className='contact'>
                            <a href='mailto:info@g-i-d.at'>info@g-i-d.at</a>
                        </div>
                        <hr
                            style={{
                                background: 'black',
                                color: 'black',
                                borderColor: 'black',
                                height: '1.5pt',
                            }}
                        />
                    </div>
                    <img width='20%' src={insta} onClick={handleLinkClick} className='insta' alt='instagram'></img>
                </div>
                <div className='box-item4'>
                    <div className='info'>
                        <p>SITE UNDER<br />CONSTRUCTION!</p>
                    </div>
                </div>
                {/*  */}

                {/* <div className='box-item3'>
                    <img className='animation' src={animation} alt='gid animation'></img>
                  

                </div> */}
            </div>

        </div>


    );
}

import './mobile.css'
import Logo from './../assets/GIDLOGO.png'
import insta from './../assets/Instagram.png'
import animation from './../assets/animation.gif'

export function Mobile(props) {
    function handleLinkClick() {
        window.location = "https://www.instagram.com/gidgrafik/"
    }

    return (
        <div className='box-mobile'>
            <div className='box-item-mobile'>
                <img width='60%' src={Logo} alt='gid logo'></img>
                <img className='animation-mobile' src={animation} alt='gid animation'></img>
                <div className='info-mobile'>
                    <p>SITE UNDER<br />CONSTRUCTION!</p>
                </div>
                <p className='subtitle-mobile'>
                    DRUCKWERKE | PACKAGE DESIGN<br/>SOCIAL MEDIA | ONLINE MARKETING<br/>SCREENDESIGN | GIF ANIMATION<br/>ILLUSTRATION
                </p>
                <p className='text-mobile'>
                    „Mit großer Leidenschaft und
                    viel Liebe zu schönem Design,
                    vor allem für die Wertschätzung
                    gegenüber unserer Kunden,
                    wird jedes Projekt von uns mit
                    größtem Engagement und dem
                    immerwährenden Streben nach
                    Perfektion umgesetzt.“
                </p>
                <a href='https://sweetpopcorn.studio' className='text-mobile'>Partner: sweetpopcorn.studio</a>
                <p className='text-mobile'></p>
                <div className='fullSize-mobile'>
                    <hr
                        style={{
                            background: 'black',
                            color: 'black',
                            borderColor: 'black',
                            height: '1.5pt',
                        }}
                    />
                    <div className='contact'>
                        <a href='tel:+436648787439'>T +43.664.8787439</a>
                    </div>
                    <hr
                        style={{
                            background: 'black',
                            color: 'black',
                            borderColor: 'black',
                            height: '1.5pt',
                        }}
                    />
                    <div className='contact'>
                        <a href='mailto:info@g-i-d.at'>info@g-i-d.at</a>
                    </div>
                    <hr
                        style={{
                            background: 'black',
                            color: 'black',
                            borderColor: 'black',
                            height: '1.5pt',
                        }}
                    />
                </div>
                <div>
                    <img src={insta} onClick={handleLinkClick} className='insta' alt='instagram'></img>
                </div>
                
            </div>

            {/* <div className='box-item3'>
                    <img width='60%' src={Logo} alt='gid logo'></img>
                    <p className='subtitle'>
                        DRUCKWERKE PACKAGE DESIGN SOCIAL MEDIA ONLINE MARKETING SCREENDESIGN ANIMATION GIF ILLUSTRATION
                    </p>
                    <p className='text'>
                        „Mit großer Leidenschaft und
                        viel Liebe zu schönem Design,
                        vor allem für die Wertschätzung
                        gegenüber unserer Kunden,
                        wird jedes Projekt von uns mit
                        größtem Engagement und dem
                        immerwährenden Streben nach
                        Perfektion umgesetzt.“
                    </p>
                    <p className='text'>Partner: sweetpopcorn.studio</p>
                </div>
                <div className='box-item4'>
                    <img className='animation' src={animation} alt='gid animation'></img>
                </div>


                <div className='box-item3'>
                    <div className='fullSize'>
                        <hr
                            style={{
                                background: 'black',
                                color: 'black',
                                borderColor: 'black',
                                height: '1.5pt',
                            }}
                        />
                        <div className='contact'>
                            <a href='tel:+436648787439'>T +43.664.6455410</a>
                        </div>
                        <hr
                            style={{
                                background: 'black',
                                color: 'black',
                                borderColor: 'black',
                                height: '1.5pt',
                            }}
                        />
                        <div className='contact'>
                            <a href='mailto:info@g-i-d.at'>info@g-i-d.at</a>
                        </div>
                        <hr
                            style={{
                                background: 'black',
                                color: 'black',
                                borderColor: 'black',
                                height: '1.5pt',
                            }}
                        />
                    </div>
                    <img width='20%' src={insta} onClick={handleLinkClick} className='insta' alt='instagram'></img>
                </div>
                <div className='box-item4'>
                    <div className='info'>
                        <p>SITE UNDER<br />CONSTRUCTION!</p>
                    </div>
                </div> */}
        </div>



    );
}